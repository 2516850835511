import React, {useState} from 'react'

import LayoutNoHeader from '../components/layout-noheader'
import headerBg from '../images/homepage-header-bg.png'
import Logo from '../components/logo'
import actLogo from '../images/act-logo.png'
import iconZoom from '../images/zoom-in.svg'
import iconBarChart from '../images/bar-chart.svg'
import {useQuery, gql} from '@apollo/client'
import {DebounceInput} from 'react-debounce-input'
import {Link} from 'gatsby'
import Avatar from '../images/avatar-placeholder.png'
import SEO from '../components/seo'
import axios from "axios";

const config = require('../config/default');

const headerStyles = {
    backgroundImage: `url(${headerBg})`,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    backgroundSize: 'cover',
    height: '50vh',
    minHeight: 400,
}

function DatasetPage() {

    return (
        <LayoutNoHeader>
            <SEO
                keywords={[`corrupt0`, `political`, `data`]}
                title="Corrupt0 Homepage"
            />
            <header className="">
                <div className="flex-1 w-full max-w-6xl mx-auto px-4 py-8 md:px-8 md:pb-16 md:pt-8">
                    <a href={"https://actai.co/"} >
                        <img className="absolute mt-0 ml-5 w-12 object-scale-down" src={actLogo} />
                    </a>
                    <Logo />
                </div>
            </header>

            <div className="flex-1 w-full max-w-6xl px-4 py-8 md:px-8 md:py-16 mx-auto">
                <div className="grid md:grid-rows-1 md:grid-cols-1 grid-cols-1 gap-8">
                    <div className="col-span-3 lg:col-span-1">
                        <h3 className="font-bold text-2xl"><span className="font-avenir">ดาวน์โหลดข้อมูล</span></h3>
                        <ul className="list-none">
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://drive.google.com/file/d/1y_jKC8K756BKxNpa9CgXNvmgckoEVKsZ/view?usp=drivesdk" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">ข้อมูลพรรคการเมือง</div>
                                        <div className="text-sm">Last updated: April 17, 2020</div>
                                    </div></a>
                            </li>
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://drive.google.com/file/d/1kZjkE6_BXa77rWHw6AFODMpiY_gD6InB/view?usp=drivesdk" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">ผลการเลือกตั้งทั่วไป 24 มีนาคม 2562</div>
                                        <div className="text-sm">Last updated: April 17, 2020</div>
                                    </div></a>
                            </li>
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://drive.google.com/file/d/1jpMe5cAF0A8avITImaVRU7WNL_ts-0pE/view?usp=drivesdk" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">รายชื่อผู้สมัครรับเลือกตั้งทั่วไป 24 มีนาคม 2564</div>
                                        <div className="text-sm">Last updated: April 17, 2020</div>
                                    </div></a>
                            </li>

                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://docs.google.com/file/d/1FPt5Ll-JW63KsqDz_yaB8hPBtMke5wOQ/edit?usp=docslist_api&filetype=msexcel" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">ชุดข้อมูลดิจิทัลคำชี้มูลความผิดของ ป.ป.ช. ที่เปิดเผยบนเว็บไซต์ของสำนักงาน ป.ป.ช.</div>
                                        <div className="text-sm">Last updated: September 3, 2021</div>
                                    </div></a>
                            </li>
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://drive.google.com/drive/folders/1pNGfWc0XafJDFl9ep-vJ_PM4Fo94Su4A" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">ชุดข้อมูลดิจิทัลจากสำนักงานคณะกรรมการการเลือกตั้ง (กกต.) รายนามผู้บริจาคให้พรรคการเมือง</div>
                                        <div className="text-sm">Last updated: August 13, 2021</div>
                                    </div></a>
                            </li>
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://drive.google.com/drive/folders/1cezezitAWnSRD1VjH0HbbFKUWMwp-LL3" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">ชุดข้อมูลดิจิทัลบัญชีทรัพย์สินและหนี้สินของผู้ดำรงตำแหน่งทางการเมืองที่เปิดเผยในปี พ.ศ. 2562 - 2563</div>
                                        <div className="text-sm">Last updated: July 31, 2021</div>
                                    </div></a>
                            </li>

                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://docs.google.com/file/d/1VP0qxfQ2A-jICGrPrMdgT0ctx1uKUz4v/edit?usp=docslist_api&filetype=msexcel" target="_blank">
                                    <div className="content-center">
                                        <div className="text-xl">ชุดข้อมูลดิจิทัลการบังคับใช้กฎหมายของ ก.ล.ต. ตั้งแต่ พ.ศ. 2553 - 2562 ที่เปิดเผยบนเว็บไซต์ ของ ก.ล.ต.</div>
                                        <div className="text-sm">Last updated: September 27, 2021</div>
                                    </div></a>
                            </li>
                            <li className="mt-3 mb-1 p-3 border-l-4 border-red-500 bg-white">
                                <a href="https://docs.google.com/file/d/1Bvqd_vUetfh7tB2KnwtarYWgR3pYe-Hn/edit?usp=docslist_api&filetype=msexcel">
                                    <div className="content-center">
                                        <div className="text-xl">ชุดข้อมูลดิจิทัลคำพิพากษาศาลฎีกาแผนกคดีอาญาของผู้ดำรงตำแหน่งทางการเมือง ตั้งแต่ ปี พ.ศ. 2546</div>
                                        <div className="text-sm">Last updated: August 18, 2021</div>
                                    </div></a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </LayoutNoHeader>
    )
}

export default DatasetPage
